<div class="search-container">
  <div class="dropdown">
    <div class="input-container">
      @if (showResults) {
        <mat-icon
          class="global-search-input"
          fontIcon="close"
          style="color: var(--grey_5); font-size: 26px; cursor: pointer"
          (click)="clearResults()"
        ></mat-icon>
      } @else {
        <mat-icon
          fontIcon="search"
          class="global-search-input"
          style="color: var(--grey_7); font-size: 26px"
        ></mat-icon>
      }

      <input
        #searchInput
        class="global-search-input"
        type="text"
        [(ngModel)]="inputText"
        (keyup)="onSearch()"
        [placeholder]="
          placeHolderText ||
          ('COMPONENTS.GLOBAL_SEARCH_ENGINE.PLACEHOLDER' | translate)
        "
        (focus)="onInputFocus()"
      />
    </div>
    @if (showResults || isLoading) {
      @if (!isLoading) {
        <div
          class="search-results"
          [ngClass]="{ 'has-results': results.length > 0 }"
        >
          @for (result of results; track $index) {
            <div class="result" (click)="navigateTo(result.url)">
              <div class="header">
                <div class="content_preview">
                  <div class="header_line">
                    <h3 class="title">{{ result.title }}</h3>
                    <span class="type">
                      {{ mapContentType(result.type) }}
                    </span>
                  </div>
                  <div class="img_and_text_container">
                    <div class="text_container">
                      @if (result.text) {
                        <span class="text" [innerHTML]="result.text"></span>
                      }
                    </div>
                    <div class="image_container">
                      @if (result.image) {
                        <img
                          class="img"
                          (error)="onImgError($event)"
                          ngSrc="{{ result.image }}"
                          width="150"
                          height="150"
                        />
                      } @else {
                        <img
                          class="img"
                          (error)="onImgError($event)"
                          ngSrc="/assets/svg/logo-inverted.png"
                          width="150"
                          height="150"
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      } @else {
        <div class="has-results">
          <div class="loader">
            <app-header-loader [isBodyText]="false"></app-header-loader>
          </div>
        </div>
      }
    }
  </div>
</div>
