import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  imports: [CommonModule],
  standalone: true,
  selector: 'app-header-loader',
  templateUrl: './header-loader.component.html',
  styleUrls: ['./header-loader.component.scss'],
})
export class HeaderLoaderComponent {
  @Input() isBodyText?: boolean;
}
