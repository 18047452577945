<div class="card loading_card">
  <h2>xxxxxx xxx</h2>
  @for (_ of [1, 2, 3, 4]; track $index) {
    <p class="loading_sub_header">xxxxx</p>
  }
  @if (isBodyText) {
    <div>
      @for (_ of [1, 2, 3]; track $index) {
        <div class="body_element">
          <h3>xxxxxx xxx</h3>
          @for (_ of [1, 2, 3, 4, 5, 6]; track $index) {
            <p class="loading_sub_header">xxxxx</p>
          }
        </div>
      }
    </div>
  }
</div>
