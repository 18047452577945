import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/handyhand/environment.dk';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/http/auth.service';

export interface SearchQueryResponseData {
  image: string;
  text: string;
  type: string;
  title: string;
  score: number;
  url: string;
}

export type QueryContentType =
  | 'CONTENT-GUIDE'
  | 'CONTENT-BLOG'
  | 'CORE-VERTICALS'
  | 'FAQ'
  | 'CONTENT-PRICE';

// Prioritize specific content type
export interface SearchQueryRequestData {
  query: string;
  contentType?: QueryContentType;
}
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  baseUrl: string = `${environment.dashboardBaseRef}api/search`;
  userRole: string = 'both';

  constructor(
    private authService: AuthService,
    private http: HttpClient,
  ) {
    this.userRole = 'to';
    /*   if (this.authService.isAuthenticated()) {
      this.http.get<any>(`${environment.apiUrl}/users-settings/`).subscribe({
        next: (data) => {
          this.userRole = data.pinployeeOrTaskOwner;
        },
        error: () => {
          this.userRole = 'both';
        },
      });
    } else {
      this.userRole = 'to';
    }*/
  }

  // flag prioritize, verticals, type, role,
  search(query: SearchQueryRequestData): Observable<SearchQueryResponseData[]> {
    let params = new HttpParams().set('query', query.query);

    if (query.contentType) {
      params = params.set('contentType', query.contentType);
    }

    params = params.set(
      'userRole',
      this.userRole === 'pp' ? 'hh' : this.userRole !== 'to' ? 'both' : 'to',
    );

    return this.http.get<SearchQueryResponseData[]>(
      this.baseUrl + '/global-search-engine',
      { params },
    );
  }
}
