import {
  CommonModule,
  isPlatformBrowser,
  NgOptimizedImage,
} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  SecurityContext,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslatePipe } from '@app/pipes/translate.pipe';
import {
  SearchService,
  SearchQueryResponseData,
  QueryContentType,
} from '@app/services/http/search.service';
import { AuthService } from '@services/http/auth.service';
import { HeaderLoaderComponent } from '@components/shared/loaders/header-loader/header-loader.component';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'global-search-engine',
  templateUrl: './global-search-engine.component.html',
  styleUrls: ['./global-search-engine.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    TranslatePipe,
    CommonModule,
    FormsModule,
    MatIconModule,
    HeaderLoaderComponent,
    NgOptimizedImage,
  ],
  providers: [AuthService, SearchService],
})
export class GlobalSearchEngineComponent implements OnDestroy, AfterViewInit {
  @Input() contentType?: QueryContentType;
  @Input() placeHolderText?: string;
  @Input() autoFocus: boolean = false;

  public isLoading: boolean = false;
  public inputText: string = '';
  public results: Array<SearchQueryResponseData> = [];
  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();
  showResults: boolean = false;
  private isBrowser: boolean = false;

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

  constructor(
    private searchService: SearchService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private sanitizer: DomSanitizer,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.searchSubject
      .pipe(
        debounceTime(1000),
        switchMap((query) => {
          this.isLoading = true;
          return this.searchService.search({ query });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response: SearchQueryResponseData[]) => {
          this.results = response.map((result) => ({
            ...result,
            text: this.stripHtmlTags(result.text),
          }));
          this.results.sort((a, b) => b.score - a.score);
          const verticalIndex = this.results.findIndex(
            (item) => item.type === 'VERTICAL',
          );
          if (verticalIndex > -1) {
            const [verticalItem] = this.results.splice(verticalIndex, 1);
            this.results.unshift(verticalItem);
          }
          this.isLoading = false;
          this.showResults = this.results.length > 0;
        },
        error: (error) => {
          this.results = [];
          this.isLoading = false;
        },
      });

    if (this.isBrowser) {
      document.addEventListener('click', this.onDocumentClick.bind(this));
    }
  }

  ngAfterViewInit(): void {
    if (this.autoFocus && this.searchInput && this.isBrowser) {
      this.searchInput.nativeElement.focus();
    }
  }

  @HostListener('document:click', ['$event']) onDocumentClick(
    event: MouseEvent,
  ): void {
    if (this.isBrowser) {
      const target = event.target as HTMLElement;
      if (
        !target.closest('global-search-engine') &&
        target !== this.searchInput.nativeElement
      ) {
        this.showResults = false;
      }
    }
  }

  stripHtmlTags(html: string): string {
    const sanitizedHtml =
      this.sanitizer.sanitize(SecurityContext.HTML, html) || '';
    const div = document.createElement('div');
    div.innerHTML = sanitizedHtml;
    let text = div.textContent || div.innerText || '';
    text = text.replace(/\\n/g, ' ');
    if (!text.endsWith('...')) {
      text += '...';
    }
    return text;
  }

  onSearch(): void {
    this.searchSubject.next(this.inputText);
    this.showResults = this.results.length > 0;
  }

  clearResults(): void {
    this.results = [];
    this.showResults = false;
    this.inputText = '';
  }

  onImgError(event: any) {
    event.target.src = `/assets/svg/logo-inverted.png`;
  }

  public mapContentType(type: string): string {
    switch (type) {
      case 'BLOG':
        return 'Blog';
      case 'CORE_VERTICALS':
        return 'Kategorier';
      case 'GUIDE':
        return 'Guide';
      case 'FAQ':
        return 'FAQ';
      case 'INSPIRATION':
        return 'Inspiration';
      case 'TIPS':
        return 'Tips';
      case 'PRICE':
        return 'Pris Guide';
      case 'VERTICAL':
        return 'Service';
      default:
        return type;
    }
  }

  public navigateTo(url: string): Promise<boolean> | void {
    if (url) {
      window.location.href = url;
    }
    return;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Add focus event listener to the input field
  onInputFocus(): void {
    if (this.results.length > 0) {
      this.showResults = true;
    }
  }
}
